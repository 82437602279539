export default class Analytics {
  constructor(analytics) {
    this.analytics = analytics
  }

  sendTagEvent(name, params = {}) {
    try {
      this.analytics.forEach(analytic => {
        analytic.sendTagEvent(name, params)
      })
    } catch (error) {
      // TODO add sentry
      console.log('error sendTagEvent', error, name, params)
    }
  }

  sendEvent(name, params = {}) {
    try {
      this.analytics.forEach(analytic => {
        analytic.sendEvent(name, params)
      })
    } catch (error) {
      // TODO add sentry
      console.log('error sendEvent', error, name, params)
    }
  }

  sendScreen(name, params = {}) {
    try {
      this.analytics.forEach(analytic => {
        analytic.sendScreen(name, params)
      })
    } catch (error) {
      // TODO add sentry
      console.log('error sendScreen', error, name, params)
    }
  }
}
