export const state = () => ({
  stories: [],
  footerStories: [],
  testimonies: [],
  proArea: {},
  isProDomain: false,
})

export const getters = {
  getProFooterStories: state => {
    return state.footerStories.map(x => {
      let slug = x.full_slug
      slug = slug
        .split('/')
        .slice(1, slug.split('/').length)
        .filter(y => !y.includes('-folder'))
        .join('/')
      return {
        uuid: x.uuid,
        title: x.content?.alternate_title || x.content?.title || x.name,
        slug,
      }
    })
  },
  proArea: state => {
    return state.proArea
  },
  getStoryBySlug: state => slug => {
    return state.stories.find(x => x.slug === slug)
  },
  getStoryByUid: state => uid => {
    return state.stories.find(x => x.uuid === uid)
  },
  getTestimonyByUid: state => uid => {
    return state.testimonies.find(x => x.uuid === uid)
  },
  getServices: state => {
    if (state.proArea['espace-pro']) {
      return state.proArea['espace-pro'].services
    }
    return state.proArea.services
  },
  getServicesParentList: state => {
    if (state.proArea['espace-pro']) {
      return Object.keys(state.proArea['espace-pro'].services)?.filter(x => x?.includes('folder'))
    }
    return Object.keys(state.proArea.services)?.filter(x => x?.includes('folder'))
  },
  getServicesChildList: state => slug => {
    if (state.proArea['espace-pro']) {
      return Object.keys(state.proArea['espace-pro'].services[slug])
    }
    return Object.keys(state.proArea.services[slug])
  },
  getOffers: state => {
    if (state.proArea['espace-pro']) {
      return state.proArea['espace-pro'].offers
    }
    return state.proArea.offers
  },
  getOffersParentList: state => {
    if (state.proArea['espace-pro']) {
      return Object.keys(state.proArea['espace-pro'].offers).filter(x => x.includes('folder'))
    }
    return Object.keys(state.proArea.offers).filter(x => x.includes('folder'))
  },
  getOffersChildList: state => slug => {
    if (state.proArea['espace-pro']) {
      return Object.keys(state.proArea['espace-pro'].offers[slug])
    }
    return Object.keys(state.proArea.offers[slug])
  },
  isProDomain: state => {
    return state.isProDomain
  },
}
const version = process.env.STORYBLOK_ENV === 'production' ? 'published' : 'draft'
export const actions = {
  async fetchProAreaStories({ commit, rootGetters }) {
    // let date = new Date()
    const proStories = await this.$storyapi.get('cdn/stories', {
      version,
      starts_with: 'espace-pro',
      sort_by: 'name:asc',
      per_page: 100,
      // cv: process.env.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
      language: rootGetters['i18nStore/langCode'](true),
    })
    const { stories } = proStories.data
    commit('setProStories', stories)
    const testimonyStories = await this.$storyapi.get('cdn/stories', {
      version,
      starts_with: 'testimony',
      sort_by: 'name:asc',
      // cv: process.env.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
      language: rootGetters['i18nStore/langCode'](true),
    })
    const { stories: testimonies } = testimonyStories.data
    commit('setTestimonyStories', testimonies)
  },
  async fetchProAreaFooterStories({ commit, rootGetters }) {
    // let date = new Date()
    const proStories = await this.$storyapi.get('cdn/stories', {
      version,
      starts_with: 'espace-pro',
      sort_by: 'name:asc',
      filter_query: {
        available_in_footer: {
          is: true,
        },
      },
      per_page: 100,
      // cv: process.env.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
      language: rootGetters['i18nStore/langCode'](true),
    })
    const { stories } = proStories.data
    commit('setProFooterStories', stories)
  },
}

export const mutations = {
  setProStories(state, stories) {
    state.stories = stories
    function recursiveSlug(obj, slug, i, slugs, story) {
      i++
      if (!obj[slug]) obj[slug] = {}
      if (slug.includes('-folder') || (i < slugs.length && slugs[i])) {
        recursiveSlug(obj[slug], slugs[i], i, slugs, story)
      } else {
        obj[slug] = story
      }
    }
    state.proArea = stories.reduce(
      (a, story) => {
        let slugs = story.full_slug.split('/').slice(1)
        recursiveSlug(a, slugs[0], 0, slugs, story)
        return a
      },
      { home: {}, services: {}, offers: {}, tarifs: {} }
    )
  },
  setProFooterStories(state, stories) {
    state.footerStories = stories
  },
  setTestimonyStories(state, stories) {
    state.testimonies = stories
  },
  setIsProDomain(state) {
    state.isProDomain = true
  },
}
