import { DateTime } from 'luxon'

const UPDATE_DELAY = 60 * 60 * 10000 /* ms */

export const state = () => ({
  dynamicContentLocale: null,
  fullContentStored: false,
  marketplaceAds: {
    storySlug: 'marketplaceads',
    lastUpdate: null,
    data: null,
  },
  featuredProducts: {
    lastUpdate: null,
    data: [],
  },
  featuredBrands: {
    storySlug: 'featuredbrand',
    lastUpdate: null,
    data: [],
  },
  featuredSellers: {
    storySlug: 'featuredsellers',
    lastUpdate: null,
    data: [],
  },
  featuredInspirations: {
    storySlug: 'featuredinspirations',
    lastUpdate: null,
    data: [],
  },
  featuredSeasonalSelections: {
    storySlug: 'featuredseasonalselections',
    lastUpdate: null,
    data: [],
  },
  homeBanners: {
    storySlug: 'homebanners',
    lastUpdate: null,
    data: [],
  },
  news: {
    storySlug: 'news',
    lastUpdate: null,
    data: [],
  },
  homeContent: {
    storySlug: 'homecontent',
    lastUpdate: null,
    data: [],
  },
  counters: {
    co2: null,
    products: null,
  },
  blogArticles: {
    storySlug: 'BlogArticle',
    lastUpdate: null,
    data: [],
  },
  blogCategories: {
    storySlug: 'blogcategory',
    lastUpdate: null,
    data: [],
  },
  blogTags: {
    storySlug: 'blogtag',
    lastUpdate: null,
    data: [],
  },
  categoryBrands: {
    storySlug: 'CategoryBrand',
    lastUpdate: null,
    data: [],
  },
})

export const getters = {
  blogArticles: state =>
    state.blogArticles.data.filter(blog => {
      return blog?.content?.scheduled_publication_at
        ? DateTime.now() > DateTime.fromSQL(blog?.content?.scheduled_publication_at)
        : true
    }),
  getBlogArticleBySlug: state => slug => {
    return state.blogArticles.data.find(
      x => !!x.translated_slugs.find(y => y.path?.split('/')?.pop() === slug) || x.slug === slug
    )
  },
  getBlogArticleByUid: state => uid => {
    return state.blogArticles.data.find(x => x.uuid === uid)
  },
  getBlogCategoryByUid: state => uid => {
    return state.blogCategories.data.find(x => x.uuid === uid)
  },
  blogCategories: state => state.blogCategories.data,
  getBlogCategoryBySlug: state => slug => {
    return state.blogCategories.data.find(x => x.slug === slug)
  },
  getBlogArticlesByCategory: (state, getters) => uid => {
    return getters.blogArticles.filter(x => x.content.category === uid)
  },
  blogTags: state => state.blogTags.data,
  getBlogTagBySlug: state => slug => {
    return state.blogTags.data.find(x => x.slug === slug)
  },
  getBlogTagByUid: state => uid => {
    return state.blogTags.data.find(x => x.uuid === uid)
  },
  getBlogTagUidByTag: state => tag => {
    return state.blogTags.data.find(x => x.slug === tag)
  },
  getBlogArticlesByTagUid: (state, getters) => tagUid => {
    return getters.blogArticles.filter(x => x.content?.tags?.includes(tagUid))
  },
  marketplaceAds: state => state.marketplaceAds.data,
  featuredProducts: state => state.featuredProducts.data,
  featuredBrands: state => state.featuredBrands.data,
  featuredSellers: state => state.featuredSellers.data,
  featuredInspirations: state => state.featuredInspirations.data,
  categoryBrands: state => state.categoryBrands.data,
  featuredInspirationsSelected: state => url => {
    return state.featuredInspirations.data.find(x => {
      return url.split('?')?.[0].includes(x.url.split('?')?.[0]) || x.url.includes(url) || url.includes(x.url)
    })
  },
  featuredSeasonalSelections: state => {
    return state.featuredSeasonalSelections.data.filter(x => !x.menu)
  },
  featuredSeasonalSelectionsMenu: state => {
    return state.featuredSeasonalSelections.data.find(x => x.menu)
  },
  topBanner: state => {
    let topBanner = state.homeBanners.data?.find(
      b =>
        b?.target === 'event' &&
        b?.global_top_banner &&
        DateTime.now() < DateTime.fromSQL(b?.end_date) &&
        (b?.start_date ? Date.now() > DateTime.fromSQL(b?.start_date) : true)
    )
    if (topBanner) {
      return topBanner
    }
  },
  homeBanner: state => currentUser => {
    let eventBanner = state.homeBanners.data.find(
      b =>
        b.target === 'event' &&
        !b.global_top_banner &&
        DateTime.now() < DateTime.fromSQL(b?.end_date) &&
        (b?.start_date ? Date.now() > DateTime.fromSQL(b?.start_date) : true)
    )
    if (eventBanner) {
      return eventBanner
    } else if (currentUser.isPro && state.homeBanners.data.find(b => b.target === 'pro')) {
      return state.homeBanners.data.find(b => b.target === 'pro')
    } else if (
      !currentUser.isPro &&
      currentUser.isAuthenticated &&
      state.homeBanners.data.find(b => b.target === 'individual')
    ) {
      return state.homeBanners.data.find(b => b.target === 'individual')
    } else {
      return state.homeBanners.data.find(b => b.target === 'default')
    }
  },
  news: state => state.news.data,
  homeContent: state => position => {
    return state.homeContent.data.find(s => s.position === position)
  },
  needsUpdate: state => content => {
    if (state[content].lastUpdate === null) return true
    return Date.now() - state[content].lastUpdate >= UPDATE_DELAY
  },
  contentNeedsUpdate:
    state =>
    ({ contents, locale }) => {
      const needUpdate = []
      Object.keys(state).map(key => {
        contents?.forEach(c => {
          if (
            ((c || '').toLowerCase() === (state[key]?.storySlug || '').toLowerCase() &&
              (state[key]?.lastUpdate === null ||
                DateTime.now() - DateTime.fromISO(state[key]?.lastUpdate) >= UPDATE_DELAY)) ||
            state.dynamicContentLocale !== locale
          ) {
            needUpdate.push(c)
          }
        })
      })
      return needUpdate.filter((x, i) => needUpdate?.indexOf(x) === i)
    },
}

// Removed for bug of ours brand in QA
const version = process.env.STORYBLOK_ENV === 'production' ? 'published' : 'draft'

export const actions = {
  async fetchDynamicContent({ commit, dispatch, state }, params) {
    try {
      let query = {}
      let locale = (params?.locale === 'fr' ? 'default' : params?.locale) || 'default'
      let contentTotalCount = null
      let page = 1
      if (params.page) page = params.page
      let storyQuery = params?.content?.join(',')
      if (storyQuery) {
        query.component = {
          in: storyQuery,
        }
      }
      await this.$api.storyblok
        .get('', {
          filter_query: query,
          per_page: 100,
          language: locale,
          page: page,
        })
        .then(response => {
          contentTotalCount = response.headers.total

          let mkpAds = response?.data?.stories?.filter(x => x.full_slug.includes('marketplaceads')).map(x => x?.content)
          if (mkpAds?.length) {
            commit('setMarketplaceAds', page > 1 ? [...state.marketplaceAds.data, ...mkpAds] : mkpAds)
          }

          let featuredBrand = response?.data?.stories
            ?.filter(x => x.full_slug.includes('featuredbrands'))
            .map(x => x?.content)
          if (featuredBrand?.length) {
            commit('setFeaturedBrands', page > 1 ? [...state.featuredBrands.data, ...featuredBrand] : featuredBrand)
          }

          let featuredSellers = response?.data?.stories
            ?.filter(x => x.full_slug.includes('featuredsellers'))
            .map(x => x?.content)
          if (featuredSellers?.length) {
            commit(
              'setFeaturedSellers',
              page > 1 ? [...state.featuredSellers.data, ...featuredSellers] : featuredSellers
            )
          }

          let homeBanners = response?.data?.stories
            ?.filter(x => x.full_slug.includes('homebanners'))
            .map(x => x?.content)
          if (homeBanners?.length) {
            commit('setHomeBanners', page > 1 ? [...state.homeBanners.data, ...homeBanners] : homeBanners)
          }

          let homeContent = response?.data?.stories
            ?.filter(x => x.full_slug.includes('homecontent'))
            .map(x => x?.content)
          if (homeContent?.length) {
            commit('setHomeContent', page > 1 ? [...state.homeContent.data, ...homeContent] : homeContent)
          }

          let featuredInspirations = response?.data?.stories
            ?.filter(x => x.full_slug.includes('featuredinspirations'))
            .map(x => x?.content)
          if (featuredInspirations?.length) {
            commit(
              'setFeaturedInspirations',
              page > 1 ? [...state.featuredInspirations.data, ...featuredInspirations] : featuredInspirations
            )
          }

          let featuredSeasonalSelections = response?.data?.stories
            ?.filter(x => x.full_slug.includes('featuredseasonalselections'))
            .map(x => x?.content)
          if (featuredSeasonalSelections?.length) {
            commit(
              'setFeaturedSeasonalSelections',
              page > 1
                ? [...state.featuredSeasonalSelections.data, ...featuredSeasonalSelections]
                : featuredSeasonalSelections
            )
          }

          let news = response?.data?.stories?.filter(x => x.full_slug.includes('news')).map(x => x?.content)
          if (news?.length) {
            commit('setNews', page > 1 ? [...state.news.data, ...news] : news)
          }

          let blogArticles = response?.data?.stories?.filter(x => x.content.component === 'BlogArticle')
          if (blogArticles?.length) {
            commit('setBlogArticles', page > 1 ? [...state.blogArticles.data, ...blogArticles] : blogArticles)
          }

          let blogCategories = response?.data?.stories?.filter(x => x.full_slug.includes('blog/categories'))
          if (blogCategories?.length) {
            commit('setBlogCategories', page > 1 ? [...state.blogCategories.data, ...blogCategories] : blogCategories)
          }

          let blogTags = response?.data?.stories?.filter(x => x.full_slug.includes('blog/tags'))
          if (blogTags?.length) {
            commit('setBlogTags', page > 1 ? [...state.blogTags.data, ...blogTags] : blogTags)
          }

          let categoryBrand = response?.data?.stories?.filter(x => x.full_slug.includes('categorybrand'))
          if (categoryBrand?.length) {
            commit('setCategoryBrands', page > 1 ? [...state.categoryBrands.data, ...categoryBrand] : categoryBrand)
          }
        })
        .then(() => {
          if (locale) {
            commit('setDynamicContentLocale', locale === 'default' ? 'fr' : locale)
          }
          // Recursive call until all pages are handled
          if (params.getAll) {
            if (contentTotalCount > page * 100) {
              return dispatch('fetchDynamicContent', { ...params, page: page + 1 })
            } else {
              commit('setFullContentStored', true)
            }
          }
        })
    } catch (error) {
      console.log('error fetchDynamicContent', error)
    }
  },
  async fetchMarketplaceAds({ commit }, params) {
    let locale = (params?.locale === 'fr' ? 'default' : params?.locale) || 'default'
    if (locale) {
      commit('setDynamicContentLocale', locale === 'default' ? 'fr' : locale)
    }
    // let date = new Date()
    await this.$storyapi
      .get('cdn/stories', {
        version: process.env.STORYBLOK_ENV !== 'production' ? 'draft' : 'published',
        starts_with: 'marketplaceads',
        sort_by: 'published_at:asc',
        // cv: process.env.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
        per_page: 100,
        language: locale,
      })
      .then(response => {
        commit(
          'setMarketplaceAds',
          response?.data.stories
            .filter(ads => {
              return process.env.STORYBLOK_ENV !== 'production' ? ads.content?.active_qa : true
            })
            .map(ads => ads.content)
        )
      })
  },
  async fetchFeaturedProducts({ commit }) {
    await this.$api.reference.featured().then(results => {
      commit('setFeaturedProducts', results?.references)
    })
  },
  async fetchFeaturedBrands({ commit }, params) {
    let locale = (params?.locale === 'fr' ? 'default' : params?.locale) || 'default'
    // let date = new Date()
    await this.$storyapi
      .get('cdn/stories', {
        version,
        starts_with: 'featuredbrands',
        sort_by: 'published_at:asc',
        // cv: process.env.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
        per_page: 20,
        language: locale,
      })
      .then(response => {
        commit(
          'setFeaturedBrands',
          response?.data.stories.map(story => story.content)
        )
      })
  },
  async fetchCounters({ commit, state }) {
    if (state.counters.co2 === null) {
      try {
        await this.$api.meta.getInfos().then(result => {
          commit('setCounters', {
            products: result.mkp_total_count,
            co2: Math.ceil(result.co2_kilos / 1000),
          })
        })
      } catch (e) {
        // console.log(e)
        return this.localePath({ statusCode: 404, message: e.message })
      }
    }
  },
}

export const mutations = {
  setMarketplaceAds(state, ads) {
    state.marketplaceAds = {
      ...state.marketplaceAds,
      lastUpdate: DateTime.now(),
      data: ads,
    }
  },
  setFeaturedProducts(state, products) {
    state.featuredProducts = {
      ...state.featuredProducts,
      lastUpdate: DateTime.now(),
      data: products,
    }
  },
  setFeaturedBrands(state, brands) {
    state.featuredBrands = {
      ...state.featuredBrands,
      lastUpdate: DateTime.now(),
      data: brands,
    }
  },
  setFeaturedSellers(state, sellers) {
    state.featuredSellers = {
      ...state.featuredSellers,
      lastUpdate: DateTime.now(),
      data: sellers,
    }
  },
  setFeaturedInspirations(state, inspirations) {
    state.featuredInspirations = {
      ...state.featuredInspirations,
      lastUpdate: DateTime.now(),
      data: inspirations,
    }
  },
  setFeaturedSeasonalSelections(state, seasonalSelections) {
    state.featuredSeasonalSelections = {
      ...state.featuredSeasonalSelections,
      lastUpdate: DateTime.now(),
      data: seasonalSelections,
    }
  },
  setHomeBanners(state, banners) {
    state.homeBanners = {
      ...state.homeBanners,
      lastUpdate: DateTime.now(),
      data: banners,
    }
  },
  setNews(state, news) {
    state.news = {
      ...state.news,
      lastUpdate: DateTime.now(),
      data: news,
    }
  },
  setHomeContent(state, content) {
    state.homeContent = {
      ...state.homeContent,
      lastUpdate: DateTime.now(),
      data: content,
    }
  },
  setCounters(state, counters) {
    state.counters = counters
  },
  setBlogArticles(state, content) {
    state.blogArticles = {
      ...state.blogArticles,
      lastUpdate: DateTime.now(),
      data: content,
    }
  },
  setBlogCategories(state, content) {
    state.blogCategories = {
      ...state.blogCategories,
      lastUpdate: DateTime.now(),
      data: content,
    }
  },
  setBlogTags(state, content) {
    state.blogTags = {
      ...state.blogTags,
      lastUpdate: DateTime.now(),
      data: content,
    }
  },
  setDynamicContentLocale(state, locale) {
    state.dynamicContentLocale = locale
  },
  setCategoryBrands(state, brands) {
    state.categoryBrands = {
      ...state.categoryBrands,
      lastUpdate: DateTime.now(),
      data: brands,
    }
  },
  setFullContentStored(state, isFullContentStored) {
    state.fullContentStored = isFullContentStored
  },
}
