export const state = () => ({
  landingDownload: null,
  landingDynamic: null,
})

export const getters = {
  landingDownload: state => state.landingDownload,
  landingDynamic: state => state.landingDynamic,
}

export const actions = {
  async fetchLandingDownload({ commit, rootGetters }, partner = 'stockpro') {
    // let date = new Date()
    await this.$storyapi
      .get(`cdn/stories/landingdownload/${partner}`, {
        version: process.env.STORYBLOK_ENV === 'production' ? 'published' : 'draft',
        // cv: process.env.STORYBLOK_ENV === 'production' ? date.setHours(date.getHours(), 0, 0, 0) : Date.now(),
        language: rootGetters['i18nStore/langCode'](true),
      })
      .then(response => {
        commit('setLandingDownload', response.data.story.content)
      })
  },
}

export const mutations = {
  setLandingDownload(state, landingDownload) {
    state.landingDownload = landingDownload
  },
  setLandingDynamic(state, landingDynamic) {
    state.landingDynamic = landingDynamic
  },
}
