
import API from '@/mixins/sp-api-mixin'
import Club from '@/mixins/club-mixin'
import URL from '@/mixins/url-mixin'
import Mobile from '@/mixins/mobile-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import BurgerMenu from '@/components/BurgerMenu/BurgerMenu'
import SearchBar from '@/components/SearchBar/SearchBar'
import MainButton from '@/components/Buttons/MainButton/MainButton'
import LangSwitcher from '@/components/I18n/LangSwitcher'
import CompanySelector from '@/components/CompanySelector/CompanySelector'

export default {
  name: 'HeaderIndex',
  components: {
    BurgerMenu,
    SearchBar,
    MainButton,
    LangSwitcher,
    CompanySelector,
  },
  mixins: [API, Club, URL, Mobile, i18nMx],
  data() {
    return {
      isSmallerMd: false,
      menuStatus: false,
      showCompanyList: false,
      showMenu: false,
      proUrl: '',
      routesTopNav: ['index', 'default', 'product', 'category', 'brand'],
      isLoading: true,
    }
  },
  computed: {
    cartQuantity() {
      return this.$cart.totalItems.toString()
    },
    user() {
      return this.$currentUser
    },
    topBanner() {
      let topBanner = this.$store.getters['dynamicContent/topBanner']
      if (topBanner && typeof topBanner?.title === 'object') {
        return { content: this.$storyapi.richTextResolver.render(topBanner.title), ID: topBanner.ID }
      }
      return null
    },
    isAutoTest() {
      return this.$route.params.axeptio_disabled === 1 || this.$route.params.location_modal_disabled === 1
    },
    stockButton() {
      return !this.isClub && this.user.isPro && !this.user.isFreemium
    },
  },
  mounted() {
    this.isLoading = false
    this.onResize()
    window.onresize = this.onResize
    this.proUrl = this.getProUrl()
  },
  methods: {
    logout() {
      this.$currentUser.logout().finally(() => {
        this.$analytics.sendEvent(this.$events.LOGOUT)
        this.$cart.setIsReady(false)
      })
    },
    onResize() {
      if (window.innerWidth <= 1024) this.isSmallerMd = true
      else this.isSmallerMd = false
    },
    handleMenu() {
      this.menuStatus = !this.menuStatus
    },
    openMenuCategories() {
      this.$bus.$emit(this.$events.OPEN_BURGER_MENU_CATEGORIES)
    },

    redirectToStock() {
      if (process.client && this.isClub) {
        const host = window.location.host
        window.location.replace(window.location.protocol + '//' + host?.substring(host?.indexOf('.') + 1) + '/stock')
      } else {
        this.$router.replace(this.localePath('stock'))
      }
    },
    getClubUrl(club) {
      if (process.client) {
        return window.location.host.includes('www')
          ? `${window.location.protocol}//${window.location.host.replace('www', club.key)}`
          : `${window.location.protocol}//${club.key}.${window.location.host}`
      } else {
        return ''
      }
    },
    onOpenCart() {
      this.$analytics.sendTagEvent(this.$events.TAG_PLAN.MKP.CLICK_CART)
    },
    callToggleMenu() {
      this.$refs.burgerMenu.toggleMenu()
    },
  },
}
