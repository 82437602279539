import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _126ea239 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _07b969e6 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _06a99a17 = () => interopDefault(import('../pages/cgu.vue' /* webpackChunkName: "pages/cgu" */))
const _47170a99 = () => interopDefault(import('../pages/cguMobile.vue' /* webpackChunkName: "pages/cguMobile" */))
const _06b7b198 = () => interopDefault(import('../pages/cgv.vue' /* webpackChunkName: "pages/cgv" */))
const _4808854c = () => interopDefault(import('../pages/cgvMobile.vue' /* webpackChunkName: "pages/cgvMobile" */))
const _0d46ba04 = () => interopDefault(import('../pages/club-domain/index.vue' /* webpackChunkName: "pages/club-domain/index" */))
const _30a2e610 = () => interopDefault(import('../pages/coming-soon/index.vue' /* webpackChunkName: "pages/coming-soon/index" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _463b82dc = () => interopDefault(import('../pages/mission.vue' /* webpackChunkName: "pages/mission" */))
const _0005c5e8 = () => interopDefault(import('../pages/press/index.vue' /* webpackChunkName: "pages/press/index" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _14707dd0 = () => interopDefault(import('../pages/privacyMobile.vue' /* webpackChunkName: "pages/privacyMobile" */))
const _7a3ae551 = () => interopDefault(import('../pages/pro-domain/index.vue' /* webpackChunkName: "pages/pro-domain/index" */))
const _6a13e351 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _0ea788f8 = () => interopDefault(import('../pages/qrcode.vue' /* webpackChunkName: "pages/qrcode" */))
const _fd338950 = () => interopDefault(import('../pages/root-domain/index.vue' /* webpackChunkName: "pages/root-domain/index" */))
const _188f82f6 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _54fe454a = () => interopDefault(import('../pages/cart/checkout.vue' /* webpackChunkName: "pages/cart/checkout" */))
const _746a23b8 = () => interopDefault(import('../pages/cart/login.vue' /* webpackChunkName: "pages/cart/login" */))
const _6cf2f467 = () => interopDefault(import('../pages/cart/payment_complete.vue' /* webpackChunkName: "pages/cart/payment_complete" */))
const _6f1dfb58 = () => interopDefault(import('../pages/category/best-deals/index.vue' /* webpackChunkName: "pages/category/best-deals/index" */))
const _293908e5 = () => interopDefault(import('../pages/category/best-sellers/index.vue' /* webpackChunkName: "pages/category/best-sellers/index" */))
const _2313b565 = () => interopDefault(import('../pages/category/green-everyday/index.vue' /* webpackChunkName: "pages/category/green-everyday/index" */))
const _5149026b = () => interopDefault(import('../pages/category/new-products/index.vue' /* webpackChunkName: "pages/category/new-products/index" */))
const _2e1edb54 = () => interopDefault(import('../pages/category/rooms/index.vue' /* webpackChunkName: "pages/category/rooms/index" */))
const _590c133c = () => interopDefault(import('../pages/category/seasonal-selections/index.vue' /* webpackChunkName: "pages/category/seasonal-selections/index" */))
const _620eca9a = () => interopDefault(import('../pages/category/special-discounts/index.vue' /* webpackChunkName: "pages/category/special-discounts/index" */))
const _04083969 = () => interopDefault(import('../pages/login/activation/index.vue' /* webpackChunkName: "pages/login/activation/index" */))
const _459ec5f9 = () => interopDefault(import('../pages/login/set-password/index.vue' /* webpackChunkName: "pages/login/set-password/index" */))
const _6687f0d1 = () => interopDefault(import('../pages/my-account/orders/index.vue' /* webpackChunkName: "pages/my-account/orders/index" */))
const _fd24de3a = () => interopDefault(import('../pages/my-account/quotes/index.vue' /* webpackChunkName: "pages/my-account/quotes/index" */))
const _4bc08522 = () => interopDefault(import('../pages/my-account/quotes-sales/index.vue' /* webpackChunkName: "pages/my-account/quotes-sales/index" */))
const _210dac14 = () => interopDefault(import('../pages/my-account/sales/index.vue' /* webpackChunkName: "pages/my-account/sales/index" */))
const _02454f22 = () => interopDefault(import('../pages/pro-domain/contact/index.vue' /* webpackChunkName: "pages/pro-domain/contact/index" */))
const _4ea45b76 = () => interopDefault(import('../pages/pro-domain/tarifs/index.vue' /* webpackChunkName: "pages/pro-domain/tarifs/index" */))
const _edb3e11c = () => interopDefault(import('../pages/root-domain/download.vue' /* webpackChunkName: "pages/root-domain/download" */))
const _654271fc = () => interopDefault(import('../pages/root-domain/download-premium.vue' /* webpackChunkName: "pages/root-domain/download-premium" */))
const _54d8fb60 = () => interopDefault(import('../pages/root-domain/landing/index.vue' /* webpackChunkName: "pages/root-domain/landing/index" */))
const _1de4a91f = () => interopDefault(import('../pages/root-domain/stock/index.vue' /* webpackChunkName: "pages/root-domain/stock/index" */))
const _ddf656f2 = () => interopDefault(import('../pages/root-domain/subscription.vue' /* webpackChunkName: "pages/root-domain/subscription" */))
const _2a690712 = () => interopDefault(import('../pages/root-domain/landing/boost-my-sales.vue' /* webpackChunkName: "pages/root-domain/landing/boost-my-sales" */))
const _2d4f7744 = () => interopDefault(import('../pages/root-domain/my-account/mkp-alerts/index.vue' /* webpackChunkName: "pages/root-domain/my-account/mkp-alerts/index" */))
const _4995a5f2 = () => interopDefault(import('../pages/root-domain/my-account/onboarding/index.vue' /* webpackChunkName: "pages/root-domain/my-account/onboarding/index" */))
const _c451402c = () => interopDefault(import('../pages/cart/confirmation/bancontact/_uid.vue' /* webpackChunkName: "pages/cart/confirmation/bancontact/_uid" */))
const _6d899120 = () => interopDefault(import('../pages/cart/confirmation/ideal/_uid.vue' /* webpackChunkName: "pages/cart/confirmation/ideal/_uid" */))
const _093603ea = () => interopDefault(import('../pages/my-account/sales/issues/_uid.vue' /* webpackChunkName: "pages/my-account/sales/issues/_uid" */))
const _5666c93a = () => interopDefault(import('../pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/index.vue' /* webpackChunkName: "pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/index" */))
const _59954638 = () => interopDefault(import('../pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/email/_email.vue' /* webpackChunkName: "pages/root-domain/my-account/mkp-alerts/_uid/unsubscribe/email/_email" */))
const _03242ec2 = () => interopDefault(import('../pages/blog/category/_category.vue' /* webpackChunkName: "pages/blog/category/_category" */))
const _1ad32bfa = () => interopDefault(import('../pages/blog/tag/_tag.vue' /* webpackChunkName: "pages/blog/tag/_tag" */))
const _7e988266 = () => interopDefault(import('../pages/cart/confirmation/_uid.vue' /* webpackChunkName: "pages/cart/confirmation/_uid" */))
const _6d675a10 = () => interopDefault(import('../pages/category/best-deals/_slug.vue' /* webpackChunkName: "pages/category/best-deals/_slug" */))
const _2782679d = () => interopDefault(import('../pages/category/best-sellers/_slug.vue' /* webpackChunkName: "pages/category/best-sellers/_slug" */))
const _776bc3db = () => interopDefault(import('../pages/category/brand/_slug.vue' /* webpackChunkName: "pages/category/brand/_slug" */))
const _4f926123 = () => interopDefault(import('../pages/category/new-products/_slug.vue' /* webpackChunkName: "pages/category/new-products/_slug" */))
const _72d0bcd0 = () => interopDefault(import('../pages/download/monthly-report/_token.vue' /* webpackChunkName: "pages/download/monthly-report/_token" */))
const _0af9da5f = () => interopDefault(import('../pages/download/white-book/_token.vue' /* webpackChunkName: "pages/download/white-book/_token" */))
const _3d9bbe62 = () => interopDefault(import('../pages/my-account/orders/_uid.vue' /* webpackChunkName: "pages/my-account/orders/_uid" */))
const _5dc6dbf1 = () => interopDefault(import('../pages/my-account/quotes-sales/_uid.vue' /* webpackChunkName: "pages/my-account/quotes-sales/_uid" */))
const _8b4e57e0 = () => interopDefault(import('../pages/my-account/quotes/_uid.vue' /* webpackChunkName: "pages/my-account/quotes/_uid" */))
const _18bc4c9d = () => interopDefault(import('../pages/my-account/sales/_uid.vue' /* webpackChunkName: "pages/my-account/sales/_uid" */))
const _3c4921e3 = () => interopDefault(import('../pages/pro-domain/offers/_slug.vue' /* webpackChunkName: "pages/pro-domain/offers/_slug" */))
const _48de89aa = () => interopDefault(import('../pages/pro-domain/services/_slug.vue' /* webpackChunkName: "pages/pro-domain/services/_slug" */))
const _53225a18 = () => interopDefault(import('../pages/root-domain/landing/_slug.vue' /* webpackChunkName: "pages/root-domain/landing/_slug" */))
const _fc7f6c4c = () => interopDefault(import('../pages/root-domain/partner/_partner.vue' /* webpackChunkName: "pages/root-domain/partner/_partner" */))
const _64c8920a = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _54a91cfa = () => interopDefault(import('../pages/brand/_brand.vue' /* webpackChunkName: "pages/brand/_brand" */))
const _155df523 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _5ee90e30 = () => interopDefault(import('../pages/merchant/_slug/index.vue' /* webpackChunkName: "pages/merchant/_slug/index" */))
const _aa004626 = () => interopDefault(import('../pages/press/_article.vue' /* webpackChunkName: "pages/press/_article" */))
const _9203cc20 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _a66fb368 = () => interopDefault(import('../pages/search/_keywords.vue' /* webpackChunkName: "pages/search/_keywords" */))
const _2c7ba973 = () => interopDefault(import('../pages/merchant/_slug/about-us/index.vue' /* webpackChunkName: "pages/merchant/_slug/about-us/index" */))
const _2439830e = () => interopDefault(import('../pages/merchant/_slug/products/index.vue' /* webpackChunkName: "pages/merchant/_slug/products/index" */))
const _2b9d4f8b = () => interopDefault(import('../pages/merchant/_slug/products/search/_keywords.vue' /* webpackChunkName: "pages/merchant/_slug/products/search/_keywords" */))
const _5494564f = () => interopDefault(import('../pages/merchant/_slug/products/_slugCategory.vue' /* webpackChunkName: "pages/merchant/_slug/products/_slugCategory" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _999675e2,
    name: "blog"
  }, {
    path: "/brands",
    component: _126ea239,
    name: "brands"
  }, {
    path: "/cart",
    component: _07b969e6,
    name: "cart"
  }, {
    path: "/cgu",
    component: _06a99a17,
    name: "cgu"
  }, {
    path: "/cguMobile",
    component: _47170a99,
    name: "cguMobile"
  }, {
    path: "/cgv",
    component: _06b7b198,
    name: "cgv"
  }, {
    path: "/cgvMobile",
    component: _4808854c,
    name: "cgvMobile"
  }, {
    path: "/club-domain",
    component: _0d46ba04,
    name: "club-domain"
  }, {
    path: "/coming-soon",
    component: _30a2e610,
    name: "coming-soon"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/mission",
    component: _463b82dc,
    name: "mission"
  }, {
    path: "/press",
    component: _0005c5e8,
    name: "press"
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy"
  }, {
    path: "/privacyMobile",
    component: _14707dd0,
    name: "privacyMobile"
  }, {
    path: "/pro-domain",
    component: _7a3ae551,
    name: "pro-domain"
  }, {
    path: "/products",
    component: _6a13e351,
    name: "products"
  }, {
    path: "/qrcode",
    component: _0ea788f8,
    name: "qrcode"
  }, {
    path: "/root-domain",
    component: _fd338950,
    name: "root-domain"
  }, {
    path: "/signup",
    component: _188f82f6,
    name: "signup"
  }, {
    path: "/cart/checkout",
    component: _54fe454a,
    name: "cart-checkout"
  }, {
    path: "/cart/login",
    component: _746a23b8,
    name: "cart-login"
  }, {
    path: "/cart/payment_complete",
    component: _6cf2f467,
    name: "cart-payment_complete"
  }, {
    path: "/category/best-deals",
    component: _6f1dfb58,
    name: "category-best-deals"
  }, {
    path: "/category/best-sellers",
    component: _293908e5,
    name: "category-best-sellers"
  }, {
    path: "/category/green-everyday",
    component: _2313b565,
    name: "category-green-everyday"
  }, {
    path: "/category/new-products",
    component: _5149026b,
    name: "category-new-products"
  }, {
    path: "/category/rooms",
    component: _2e1edb54,
    name: "category-rooms"
  }, {
    path: "/category/seasonal-selections",
    component: _590c133c,
    name: "category-seasonal-selections"
  }, {
    path: "/category/special-discounts",
    component: _620eca9a,
    name: "category-special-discounts"
  }, {
    path: "/login/activation",
    component: _04083969,
    name: "login-activation"
  }, {
    path: "/login/set-password",
    component: _459ec5f9,
    name: "login-set-password"
  }, {
    path: "/my-account/orders",
    component: _6687f0d1,
    name: "my-account-orders"
  }, {
    path: "/my-account/quotes",
    component: _fd24de3a,
    name: "my-account-quotes"
  }, {
    path: "/my-account/quotes-sales",
    component: _4bc08522,
    name: "my-account-quotes-sales"
  }, {
    path: "/my-account/sales",
    component: _210dac14,
    name: "my-account-sales"
  }, {
    path: "/pro-domain/contact",
    component: _02454f22,
    name: "pro-domain-contact"
  }, {
    path: "/pro-domain/tarifs",
    component: _4ea45b76,
    name: "pro-domain-tarifs"
  }, {
    path: "/root-domain/download",
    component: _edb3e11c,
    name: "root-domain-download"
  }, {
    path: "/root-domain/download-premium",
    component: _654271fc,
    name: "root-domain-download-premium"
  }, {
    path: "/root-domain/landing",
    component: _54d8fb60,
    name: "root-domain-landing"
  }, {
    path: "/root-domain/stock",
    component: _1de4a91f,
    name: "root-domain-stock"
  }, {
    path: "/root-domain/subscription",
    component: _ddf656f2,
    name: "root-domain-subscription"
  }, {
    path: "/root-domain/landing/boost-my-sales",
    component: _2a690712,
    name: "root-domain-landing-boost-my-sales"
  }, {
    path: "/root-domain/my-account/mkp-alerts",
    component: _2d4f7744,
    name: "root-domain-my-account-mkp-alerts"
  }, {
    path: "/root-domain/my-account/onboarding",
    component: _4995a5f2,
    name: "root-domain-my-account-onboarding"
  }, {
    path: "/cart/confirmation/bancontact/:uid?",
    component: _c451402c,
    name: "cart-confirmation-bancontact-uid"
  }, {
    path: "/cart/confirmation/ideal/:uid?",
    component: _6d899120,
    name: "cart-confirmation-ideal-uid"
  }, {
    path: "/my-account/sales/issues/:uid",
    component: _093603ea,
    name: "my-account-sales-issues-uid"
  }, {
    path: "/root-domain/my-account/mkp-alerts/:uid?/unsubscribe",
    component: _5666c93a,
    name: "root-domain-my-account-mkp-alerts-uid-unsubscribe"
  }, {
    path: "/root-domain/my-account/mkp-alerts/:uid?/unsubscribe/email/:email",
    component: _59954638,
    name: "root-domain-my-account-mkp-alerts-uid-unsubscribe-email-email"
  }, {
    path: "/blog/category/:category?",
    component: _03242ec2,
    name: "blog-category-category"
  }, {
    path: "/blog/tag/:tag?",
    component: _1ad32bfa,
    name: "blog-tag-tag"
  }, {
    path: "/cart/confirmation/:uid?",
    component: _7e988266,
    name: "cart-confirmation-uid"
  }, {
    path: "/category/best-deals/:slug?",
    component: _6d675a10,
    name: "category-best-deals-slug"
  }, {
    path: "/category/best-sellers/:slug?",
    component: _2782679d,
    name: "category-best-sellers-slug"
  }, {
    path: "/category/brand/:slug?",
    component: _776bc3db,
    name: "category-brand-slug"
  }, {
    path: "/category/new-products/:slug?",
    component: _4f926123,
    name: "category-new-products-slug"
  }, {
    path: "/download/monthly-report/:token?",
    component: _72d0bcd0,
    name: "download-monthly-report-token"
  }, {
    path: "/download/white-book/:token?",
    component: _0af9da5f,
    name: "download-white-book-token"
  }, {
    path: "/my-account/orders/:uid?",
    component: _3d9bbe62,
    name: "my-account-orders-uid"
  }, {
    path: "/my-account/quotes-sales/:uid?",
    component: _5dc6dbf1,
    name: "my-account-quotes-sales-uid"
  }, {
    path: "/my-account/quotes/:uid?",
    component: _8b4e57e0,
    name: "my-account-quotes-uid"
  }, {
    path: "/my-account/sales/:uid?",
    component: _18bc4c9d,
    name: "my-account-sales-uid"
  }, {
    path: "/pro-domain/offers/:slug",
    component: _3c4921e3,
    name: "pro-domain-offers-slug"
  }, {
    path: "/pro-domain/services/:slug",
    component: _48de89aa,
    name: "pro-domain-services-slug"
  }, {
    path: "/root-domain/landing/:slug",
    component: _53225a18,
    name: "root-domain-landing-slug"
  }, {
    path: "/root-domain/partner/:partner",
    component: _fc7f6c4c,
    name: "root-domain-partner-partner"
  }, {
    path: "/blog/:article",
    component: _64c8920a,
    name: "blog-article"
  }, {
    path: "/brand/:brand?",
    component: _54a91cfa,
    name: "brand-brand"
  }, {
    path: "/category/:slug?",
    component: _155df523,
    name: "category-slug"
  }, {
    path: "/merchant/:slug",
    component: _5ee90e30,
    name: "merchant-slug"
  }, {
    path: "/press/:article",
    component: _aa004626,
    name: "press-article"
  }, {
    path: "/product/:slug?",
    component: _9203cc20,
    name: "product-slug"
  }, {
    path: "/search/:keywords?",
    component: _a66fb368,
    name: "search-keywords"
  }, {
    path: "/merchant/:slug?/about-us",
    component: _2c7ba973,
    name: "merchant-slug-about-us"
  }, {
    path: "/merchant/:slug?/products",
    component: _2439830e,
    name: "merchant-slug-products"
  }, {
    path: "/merchant/:slug?/products/search/:keywords?",
    component: _2b9d4f8b,
    name: "merchant-slug-products-search-keywords"
  }, {
    path: "/merchant/:slug?/products/:slugCategory",
    component: _5494564f,
    name: "merchant-slug-products-slugCategory"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
